@font-face {
  font-family: "Kanit-Light";
  src: url("./fonts/Kanit-Light.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit-Bold";
  src: url("./fonts/Kanit-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  color: var(--main-color);
  font-family: "Kanit-Light", sans-serif;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
img,
ol {
  margin: 0;
  padding: 0;
  font-size: 1em;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

svg {
  display: block;
}

img {
  display: block;
  height: auto;
}

body[data-theme="dark"] {
  background-color: #453f3f;

  --main-color: #ffffffd4;
  --secondary-color: #5d99dd;
  --secondary-light-color: #ffffff;
  --main-hover: #ffffffd4;
  --second-hover: #0a79f7;
  --text-button: #453f3f;
  --background: #453f3f;
}

body[data-theme="light"] {
  --main-color: #42446e;
  --secondary-color: #a0cafb;
  --secondary-light-color: #d7e7fa87;
  --main-hover: rgba(0, 0, 0, 0.5);
  --second-hover: #1161bd;
  --text-button: #fff;
  --background: #fff;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (min-width: 480px) {
  .container {
    width: 480px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 768px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}
