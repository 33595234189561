.notification {
  position: absolute;
  display: inline-flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  top: 0;
}
.notification__wrapper {
  display: flex;
  width: 300px;
  height: 20%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--main-color);
  box-shadow: 0px 0px 10px 0px var(--main-hover);
  background-color: var(--background);
}

.notification__text {
  font-size: 16px;
  color: inherit;
}

@media screen and (min-width: 768px) {
  .notification__wrapper {
    width: 500px;
  }
  .notification__text {
    font-size: 20px;
  }
}
