.header {
  max-width: 1600px;
  margin: 0 auto;
  border-bottom: 1px solid var(--main-hover);
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.nav {
  display: none;
}

.theme {
  margin-right: 10px;
}

.header__handler {
  display: flex;
  align-items: center;
}

.theme__button {
  position: relative;
  width: 45px;
  height: 25px;
  border-radius: 15px;
  border: 1px solid var(--main-color);
  padding: 2px;
  cursor: pointer;
  background-color: transparent;
  transition: border 0.5s ease;
}

.theme__switch {
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  left: 0;
  top: 1px;
  background-color: var(--main-color);
  transition: background-color 0.5s ease;
}

.theme__button::after {
  content: "Змінити тему";
  position: absolute;
  top: -20px;
  left: 50%;
  width: 100px;
  transform: translateX(-50%);
  padding: 2px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  background-color: transparent;
  font-family: transparent;
  color: transparent;
  opacity: 0;
  transition: opacity 0.5s ease, color 0.5s ease, background-color 0.5s ease;
}

.theme__button:hover,
.theme__button:focus {
  border: 1px solid var(--secondary-color);
  transition: border 0.5s ease;
}

.theme__button:hover::after,
.theme__button:focus::after {
  color: var(--secondary-color);
  opacity: 1;
  transition: opacity 0.5s ease, color 0.5s ease;
}

.theme__button:hover .theme__switch,
.theme__button:focus .theme__switch {
  background-color: var(--secondary-color);
  transition: background-color 0.5s ease;
}

.theme__button.active {
  border: 1px solid var(--main-color);
  background-color: transparent;
  transition: border 0.5s ease, background-color 0.5s ease;
}

.theme__button.active::after {
  color: transparent;
  transition: color 0.5s ease;
}

.theme__button.active .theme__switch,
.theme__button.active .theme__switch {
  background-color: var(--main-color);
  transition: background-color 0.5s ease;
}

.active:hover::after {
  color: var(--secondary-color);
  transition: color 0.5s ease;
}

.theme__button.active:hover {
  border: 1px solid var(--secondary-color);
  transition: border 0.5s ease;
}

.theme__button.active:hover .theme__switch {
  background-color: var(--secondary-color);
  transition: background-color 0.5s ease;
}

.contacts-list {
  display: inline-flex;
  align-items: center;
}
.contacts {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.contacts__item {
  padding: 10px;
}

.contacts__item:not(:last-child) {
  margin-right: 20px;
}

.contacts__link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.contacts__svg {
  width: 25px;
  height: 25px;
  fill: var(--main-color);
  transition: fill 0.5s ease;
}

.contacts__link:hover .contacts__svg,
.contacts__link:focus .contacts__svg {
  fill: var(--secondary-color);
  transition: fill 0.5s ease;
}

@media screen and (min-width: 768px) {
  .contacts {
    width: auto;
  }
  .nav {
    display: inline-flex;
  }
  .nav__list {
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  .nav__list__item {
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    color: inherit;
    text-decoration: none;
    transition: color 0.5s ease;
  }

  .nav__list__item:hover,
  .nav__list__item:focus {
    color: var(--secondary-color);
    transition: color 0.5s ease;
  }
  .theme {
    margin-left: 20px;
  }
  .theme__switch {
    width: 24px;
    height: 24px;
    top: 2px;
  }
  .theme__button {
    width: 50px;
    height: 30px;
    padding: 4px;
  }
}

@media screen and (min-width: 1200px) {
  .nav__list__item {
    font-size: 20px;
  }
  .nav__contacts__svg {
    width: 30px;
    height: 30px;
  }
  .theme__button::after {
    font-size: 14px;
  }
}
