.button {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: var(--text-button);
  background-color: var(--main-color);
  box-shadow: 0px 0px 10px 0px var(--main-color);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}

.button:hover,
.button:focus,
.button.button-clicked:hover,
.button.button-clicked:hover {
  color: var(--main-hover);
  background-color: var(--secondary-color);
  box-shadow: 0px 0px 10px 0px var(--second-hover);
}

.button.button-clicked {
  color: var(--text-button);
  background-color: var(--main-color);
  box-shadow: 0px 0px 10px 0px var(--main-color);
}
