.projects {
  padding-top: 20px;
  padding-bottom: 20px;
}

.projects__title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects__title {
  font-family: "Kanit-Bold";
  font-size: 25px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 5px;
}

.projects__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 15px;
}

.projects__list {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.projects__item {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin-bottom: 20px;
  height: auto;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px var(--main-hover);
  transition-property: box-shadow, transform;
  transition-duration: 250ms;
  transition-timing-function: linear;
}

.projects__image {
  width: 350px;
  height: 180px;
  object-fit: cover;
  border-bottom: 1px solid var(--main-color);
}

.projects__box {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
}

.projects__box--item {
  flex-grow: 1;
}

.projects__box--item:last-child {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.projects__name {
  margin-bottom: 5px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.projects__description {
  font-size: 14px;
  margin-bottom: 5px;
}

.projects__stack {
  font-size: 14px;
  margin-bottom: 20px;
}

.projects__stack-title {
  font-family: "Kanit-Bold";
}

.projects__stack span {
  font-weight: bold;
}

.projects__links {
  display: flex;
  justify-content: space-between;
}

.projects__link-code {
  display: flex;
  color: var(--main-color);
}

.projects__svg {
  width: 20px;
  height: 20px;
  fill: var(--main-color);
  margin-right: 5px;
}
@media screen and (min-width: 768px) {
  .projects {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .projects__list {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: normal;
  }
  .projects__item {
    width: 45%;
    margin-bottom: 30px;
  }
  .projects__item:hover,
  .projects__item:focus {
    transform: scale(1.1);
    box-shadow: 0px 0px 10px 0px var(--second-hover);
  }
  .projects__title {
    font-size: 30px;
  }

  .projects__text {
    font-size: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .projects__title {
    font-size: 40px;
    margin-bottom: 10px;
  }

  .projects__text {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .projects__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 50px;
    justify-items: center;
  }

  .projects__item {
    display: flex;
    width: 350px;
    margin-bottom: 0;
  }
  .projects__name {
    margin-bottom: 10px;
    font-size: 30px;
  }
  .projects__description {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .projects__stack {
    font-size: 18px;
  }
}
