.hero {
  max-width: 1600px;
  margin: 0 auto;
  background: linear-gradient(160deg, #4981c1, #f0f8ff);
}

.hero__title-wrapper {
  text-align: center;
  width: 80%;
  margin-bottom: 20px;
}

.hero-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 0px 30px 0px;
}

.hero__title {
  font-family: "Kanit-Bold";
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
}

.hero__title-name {
  margin-left: 10px;
  background: linear-gradient(45deg, #42446e 33%, #0057b8 66%, #ffd700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero__img-wrapper {
  width: 220px;
  height: 220px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero__img-wrapper::before {
  content: " ";
  position: absolute;
  width: 220px;
  height: 220px;
  background: linear-gradient(to top, #ffd700, #0057b8);
}

.hero__img-wrapper::after {
  content: " ";
  position: absolute;
  border-radius: 50%;
  background-image: url(../assets/human.jpg);
  background-size: cover;
  width: 210px;
  height: 210px;
}

@media screen and (min-width: 768px) {
  .hero__title-wrapper {
    text-align: start;
    width: 50%;
    margin-bottom: 0;
  }
  .hero-wrapper {
    flex-direction: row;
    justify-content: space-around;
  }
  .hero__title {
    font-size: 35px;
  }
}

@media screen and (min-width: 1200px) {
  .hero__title {
    font-size: 50px;
  }
  .hero__img-wrapper {
    width: 350px;
    height: 350px;
  }

  .hero__img-wrapper::before {
    width: 350px;
    height: 350px;
  }
  .hero__img-wrapper::after {
    width: 330px;
    height: 330px;
  }
}
