.mobile {
  position: relative;
}

.mobile__button {
  display: block;
  margin-left: auto;
  border: none;
  padding: 0;
  background-color: inherit;
}

.mobile__svg {
  width: 50px;
  height: 50px;
  fill: var(--main-color);
  transition: fill 0.5s ease;
}

.mobile:hover,
.mobile:focus {
  fill: var(--secondary-color);
  transition: fill 0.5s ease;
}
.mobile__menu {
  width: 100%;
  display: none;
  position: absolute;
  position: fixed;
  z-index: 1;
  left: 0px;
  top: 0px;
  padding: 20px 30px;
  transform: translateY(-100%);
  transition: transform 0.9s ease;
}
.mobile__menu[is-open="true"] {
  display: block;
  background-color: var(--background);
  transform: translateY(0);
  transition: transform 0.9s ease;
}

.mobile__nav {
  margin-bottom: 50px;
  margin-left: 30px;
}
.mobile__nav__list {
}

.mobile__nav__item {
  margin-bottom: 20px;
}

.mobile__nav__item:last-child {
  margin-bottom: 0px;
}

.mobile__nav__link {
  font-size: 25px;
  font-weight: 500;
  padding: 10px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  transition: color 0.5s ease;
}
.mobile__contact {
  margin-left: 30px;
}

.mobile__contact__item {
  margin-bottom: 20px;
}
.mobile__contact__link {
  font-size: 25px;
  font-weight: 500;
  padding: 10px;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  transition: color 0.5s ease;
}

.mobile__nav__link:hover,
.mobile__nav__link:focus,
.mobile__contact__link:hover,
.mobile__contact__link:focus {
  color: var(--secondary-color);
  transition: color 0.5s ease;
}

@media screen and (min-width: 768px) {
  .mobile {
    display: none;
  }
}
