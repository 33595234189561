.form {
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.is-loading {
  opacity: 0.2;
  pointer-events: none;
  user-select: none;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  opacity: 1;
}

.form__title {
  font-size: 25px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: center;
}

.form__list {
  width: 300px;
}

.form__item:not(:last-child) {
  margin-bottom: 10px;
}

.form__input {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  padding: 0;
  padding-left: 10px;
  outline: none;
  border: 0;
  border-left: 2px solid var(--main-color);
  border-bottom: 2px solid var(--main-color);
  border-radius: 4px;
  transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: inherit;
  color: inherit;
}

.form__input:hover,
.form__input:focus,
.form__textarea:hover,
.form__textarea:focus {
  border-left: 2px solid var(--second-hover);
  border-bottom: 2px solid var(--second-hover);
  box-shadow: -3px 3px 3px 0px var(--second-hover);
}

.form__input::placeholder {
  color: rgba(117, 117, 117, 0.5);
}

html[data-theme="dark"] .form__input::placeholder {
  color: rgba(225, 221, 221, 0.707);
}

.form__message {
  font-size: 14px;
  color: red;
}

.form__textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  resize: none;
  padding: 10px;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 20px;
  outline: none;
  border: 0;
  border-left: 2px solid var(--main-color);
  border-bottom: 2px solid var(--main-color);
  background-color: inherit;
  color: inherit;

  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.01em;
}

.form__textarea::placeholder {
  color: rgba(117, 117, 117, 0.5);
}

.form__button {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: var(--text-button);
  background-color: var(--main-color);
  box-shadow: 0px 0px 10px 0px var(--main-color);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}

.form__button:hover,
.form__button:focus {
  color: var(--main-hover);
  background-color: var(--secondary-color);
  box-shadow: 0px 0px 10px 0px var(--second-hover);
}

@media screen and (min-width: 768px) {
  .form {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .form__list {
    width: 500px;
  }
  .form__title {
    font-size: 30px;
  }
}
